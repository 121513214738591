import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
;

@Component({
  selector: 'app-print-table',
  templateUrl: './print-table.component.html',
  styleUrls: ['./print-table.component.scss']
})
export class PrintTableComponent implements OnInit {
  @ViewChildren('printText') printTextElements!: QueryList<ElementRef>;
  title: string;
  data: any;
  modalWindow: string;
  year: number = new Date().getFullYear();
  currentDate: string = new Date().toLocaleDateString();
  textPadding: number[] = [];
  sessionNumber: number;
  useCase: string;
  coach: string;
  dateComplection: string;


  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.title = params['title'];
      this.modalWindow = params['modalWindow'];
      this.sessionNumber = params['session'];
      this.useCase = params['useCase'];
      this.coach = params['coach'];
      this.dateComplection = params['date'];
      this.data = JSON.parse(params['data']);
      let language =  params['language'] === 'english' ? 'en' : 'de';
      this.translate.use(language);
    });

    setTimeout(() => {
      window.print();
    }, 500);
  }

  ngAfterViewInit() {
    this.printTextElements.forEach((element, index) => {
      const height = element.nativeElement.offsetHeight;
      this.textPadding[index] = height < 100 ? 100 : 0;
    });
  }
}
