import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CoacheeHttpService } from "./http.service";
import { BehaviorSubject } from "rxjs";


@Injectable({ providedIn: 'root' })

export class CoacheeService {
  prefilledCoacheeEmailId = "";
  private dataSubject = new BehaviorSubject<string>('');

  constructor(private _httpService: CoacheeHttpService) { }

  setSidebarLogo(data: string) {
    this.dataSubject.next(data);
  }

  getSidebarLogo() {
    return this.dataSubject.asObservable();
  }


  deleteFile(file_info: FormData) {
    throw new Error("Method not implemented.");
  }

  //login service
  coacheeLogin(login_info) {
    return this._httpService.postCoacheeLogin(login_info)
      .pipe(map(
        res => res
      ));
  }

  coacheeLogout() {
    return this._httpService.coacheeLogout();
  }

  coacheeEmailCheck(login_info) {
    return this._httpService.postCoacheeEmailCheck(login_info)
      .pipe(map(
        res => res
      ));
  }


  // sendPasswordToEmail
  sendPasswordToEmail(email_info) {
    return this._httpService.sendPasswordToEmail(email_info)
      .pipe(map(
        res => res
      ));
  }

  //verify reset password token
  verifyPasswordResetToken(token_info) {
    return this._httpService.verifyPasswordResetToken(token_info)
      .pipe(map(
        res => res
      ));
  }

  //verify set password token
  verifyPasswordSetToken(token_info) {
    return this._httpService.verifyPasswordSetToken(token_info)
      .pipe(map(
        res => res
      ));
  }

  //reset the password
  resetPassword(password_details) {
    return this._httpService.resetPassword(password_details)
      .pipe(map(
        res => res
      ));
  }

  //set the password
  setPassword(password_details) {
    return this._httpService.setPassword(password_details)
      .pipe(map(
        res => res
      ));
  }

  createCoacheeAccount(password_details) {
    return this._httpService.createCoacheeAccount(password_details)
      .pipe(map(
        res => res
      ));
  }

  //get usecases and topics to display
  getUsecasesAndTopics(coachee_details) {
    return this._httpService.getUsecasesAndTopics(coachee_details)
      .pipe(map(
        res => res
      ));
  }

  //get usecases and topics to display
  bookSession(session_details) {
    return this._httpService.bookSession(session_details)
      .pipe(map(
        res => res
      ));
  }

  getCoacheeCurrentJourney() {
    return this._httpService.getCoacheeCurrentJourney()
      .pipe(map(
        res => res
      ));
  }

  //post coachee journey file document
  coacheeUploadJourneyFiles(coachee_journey_details) {
    return this._httpService.coacheeUploadJourneyFiles(coachee_journey_details)
      .pipe(map(
        res => res
      ));
  }

  // coachee profile update
  coacheeProfileUpdate(profile_details) {
    return this._httpService.coacheeProfileUpdate(profile_details)
      .pipe(map(
        res => res
      ));
  }

  coacheeJoinSession(journeyId, sessionId) {
    return this._httpService.coacheeJoinSession(journeyId, sessionId)
      .pipe(map(
        res => res
      ));
  }

  // coachee password update
  coacheePasswordUpdate(profile_details) {
    return this._httpService.coacheePasswordUpdate(profile_details)
      .pipe(map(
        res => res
      ));
  }

  //fetch coachee details
  fetchCoacheeProfileDetails(coachee_id) {
    return this._httpService.fetchCoacheeProfileDetails(coachee_id)
      .pipe(map(
        res => res
      ));
  }

  //edit coachee profile details
  editCoacheeProfileDetails(coachee_details) {
    return this._httpService.editCoacheeProfileDetails(coachee_details)
      .pipe(map(
        res => res
      ));
  }

  //save preparation quest and answer passing session id
  savePrepQuestAnsw(info) {
    return this._httpService.savePrepQuestAnsw(info)
      .pipe(map(
        res => res
      ));
  }

  sendPrepQuestAnsw(id, info) {
    return this._httpService.sendPrepQuestAnsw(id, info)
      .pipe(map(
        res => res
      ));
  }

  //fetch session ended details
  fetchSessionEndedDetails(journey_id) {
    return this._httpService.fetchSessionEndedDetails(journey_id)
      .pipe(map(
        res => res
      ));
  }
  //fetch preparation question and answers added by coachee
  fetchPrepQuestAndAnswers(session_id) {
    return this._httpService.fetchPrepQuestAndAnswers(session_id)
      .pipe(map(
        res => res
      ));
  }

  //fetch session ended details
  coacheeCoahingHistoryDetails(coachee_id) {
    return this._httpService.coacheeCoahingHistoryDetails(coachee_id)
      .pipe(map(
        res => res
      ));
  }


  coacheeJourneys(filterBy ) {
    return this._httpService.coacheeJourneys(filterBy )
      .pipe(
        map(res => res)
      );
  }


  //get qualitrics questions
  getCoacheeQualitricsQuestions(coachee_id_and_language) {
    return this._httpService.getCoacheeQualitricsQuestions(coachee_id_and_language)
      .pipe(map(
        res => res
      ));
  }

  //store qualitrics answers and questions of coachee
  storeCoacheeQualitricsQuestionsAnswers(question_answers) {
    return this._httpService.storeCoacheeQualitricsQuestionsAnswers(question_answers)
      .pipe(map(
        res => res
      ));
  }

  //get coachee action items
  getActionItemForCoachee(session_id) {
    return this._httpService.getActionItemForCoachee(session_id)
      .pipe(map(
        res => res
      ));
  }

  //rescheduling session
  reschedulingSession(rescheduling_info) {
    return this._httpService.reschedulingSession(rescheduling_info)
      .pipe(map(
        res => res
      ));
  }

  //fetch key take aways for coachee
  getKeyTakeawaysForCoachee(journey_info) {
    return this._httpService.getKeyTakeawaysForCoachee(journey_info)
      .pipe(map(
        res => res
      ));
  }

  //get survey answers
  fetchCoacheeSurveyAnswers(journey_details) {
    return this._httpService.fetchCoacheeSurveyAnswers(journey_details)
      .pipe(map(
        res => res
      ));
  }

  //fetchFlagToCheckIsCongratsPageOpened
  fetchFlagToCheckIsCongratsPageOpened(journey_details) {
    return this._httpService.fetchFlagToCheckIsCongratsPageOpened(journey_details)
      .pipe(map(
        res => res
      ));
  }

  //on close congrats popup
  onCloseCongratulationPage(journey_details) {
    return this._httpService.onCloseCongratulationPage(journey_details)
      .pipe(map(
        res => res
      ));
  }

  //get country list
  getCountryList() {
    return this._httpService.getCountryList()
      .pipe(map(
        res => res
      ));
  }

  //get matching in progress list
  fetchMatchingInprogressSessions(coachee_id) {
    return this._httpService.fetchMatchingInprogressSessions(coachee_id)
      .pipe(map(
        res => res
      ));
  }

  //fetch coachee active journeys
  fetchCoacheeActiveJourney(coachee_id) {
    return this._httpService.fetchCoacheeActiveJourney(coachee_id)
      .pipe(map(
        res => res
      ));
  }

    //fetch coachee active journeys
    markDocumentAsSeen(journeyId, fileId) {
      return this._httpService.markDocumentAsSeen(journeyId, fileId)
        .pipe(map(
          res => res
        ));
    }

  //search functionality
  searchCoachingHistory(value) {
    return this._httpService.searchCoachingHistory(value)
      .pipe(map(
        res => res
      ));
  }

  saveNotes(sessionId, notes) {
    return this._httpService.saveCoacheeSessionNotes(sessionId, notes)
      .pipe(map(
        res => res
      ));
  }


  getNotesDetailsForCoachee(notes) {
    return this._httpService.getNotesDetailsForCoachee(notes)
      .pipe(map(
        res => res
      ));
  }

  delete_coachee_file(fileid) {
    return this._httpService.delete_coachee_file(fileid)
      .pipe(map(
        res => res
      ));
  }
}
